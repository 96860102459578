import {
  dynamicRenderClassToElement,
  whenElementExists,
} from '$shared/utils.js';

// Custom elements
whenElementExists('tmp-animation', () => import('./animation_component.js'));
whenElementExists('tmp-confirm-submit', () =>
  import('./confirm_submit_component.js')
);
whenElementExists('tmp-audio-player', () =>
  import('./audio_player_component.js')
);
whenElementExists('tmp-disclosure', () => import('./disclosure_component.js'));
whenElementExists('tmp-datawrapper-embed', () =>
  import('./datawrapper_embed_component.js')
);
whenElementExists('tmp-footnote', () => import('./footnote_component.js'));
whenElementExists('tmp-series-page', () =>
  import('./series_page_component.js')
);
whenElementExists('tmp-stream', () => import('./stream_component.js'));
whenElementExists('tmp-captcha-protected-form', () =>
  import('./captcha_protected_form.js')
);
whenElementExists('tmp-sensitive-content-component', () =>
  import('./shortcode/sensitive_content_component.js')
);

// JavaScript
whenElementExists('[data-impact-item-id]', () =>
  import('./impact/featured_items_component.js')
);
whenElementExists('[data-sidebar-v1],[data-sidebar-germany]', () =>
  import('./series_sidebar_component.js')
);
dynamicRenderClassToElement(
  '.tmp-photo-slideshow-endrun',
  () => import('./photo_slideshow_component.js'),
  { skipRender: true }
);
whenElementExists('.dropdown', () => import('./dropdown_menu_component.js'));
